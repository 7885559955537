import { useState, useEffect, useMemo } from "react";
import countryList from "react-select-country-list";
import "../../static/css/clubs/HomeClubs.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
import axios from "../../utils/axios";
import CreditCardForm from "../../components/clubs/CreditCardForm";
import { useNavigate } from "react-router-dom";

const HomeClubs = () => {
  const axiosPrivate = useAxiosPrivate();
  const [info, setInfo] = useState({});
  const [montos, setMontos] = useState({});
  const { auth } = useAuth();
  const navigate = useNavigate();
  const id = jwtDecode(localStorage.getItem("refreshToken")).id;
  const paises = useMemo(() => countryList().getData(), []);

  const handleChangePais = (event) => {
    setInfo({ ...info, Pais: event.target.value });
  };

  useEffect(() => {
    if (jwtDecode(auth.accessToken).firstLog === true) {
      navigate("/change-password");
    } else {
      let isMounted = true;
      const controller = new AbortController();
      const getInfo = async () => {
        try {
          const response = await axiosPrivate.get("/api/clubdatafetch/", {
            signal: controller.signal,
            params: {
              id: id,
            },
          });
          isMounted && setInfo(response.data.data.clubInfo);
        } catch (err) {}
      };
      const getMontos = async () => {
        try {
          const response = await axiosPrivate.get(
            `/api/club/calcularmonto/${id}`,
            {
              signal: controller.signal,
            }
          );
          isMounted && setMontos(response.data.data);
        } catch (err) {}
      };
      getInfo();
      getMontos();
    }
  }, [axiosPrivate, id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `/api/club/info/${id}/`,
        JSON.stringify({
          Nombre: info.Nombre,
          NombreCorto: info.NombreCorto,
          Email: info.Email,
          Telefono: info.Telefono,
          Pais: info.Pais,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${auth.accessToken}`,
          },
        }
      );
      alert("Informacion actualizada correctamente!");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="HomeClubs">
      <header>
        <p>Registro Copa IGA 2023</p>
      </header>
      <div className="main">
        {info.isEnrolled ? (
          <>
            <p className="subtitulo">
              El club ha sido registrado satisfactoriamente!
            </p>
            {montos && (
              <div className="main">
                <table>
                  <thead>
                    <th>Item</th>
                    <th>Monto</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Monto Atletas</td>
                      <td>{`$${montos.montoAtletas}`}</td>
                    </tr>
                    <tr>
                      <td>Monto Equipos</td>
                      <td>{`$${montos.montoEquipos}`}</td>
                    </tr>
                    <tr>
                      <td>Monto Total</td>
                      <td>{`$${montos.montoTotal}`}</td>
                    </tr>
                  </tbody>
                </table>
                <CreditCardForm data={montos.montoTotal} />
              </div>
            )}
            <a href="/login">
              <button className="boton">Regresar</button>
            </a>
          </>
        ) : (
          <>
            {/* <h1>Muchas gracias por su interés, las inscripciones para la Copa IGA 2023 ya cerraron. ¡Nos vemos el próximo año!</h1> */}
            <div className="inscripcion">
              <p className="subtitulo">Informacion del club</p>
              <form onSubmit={handleSubmit} className="EditForm">
                <label htmlFor="Nombre">Nombre:</label>
                <input
                  name="Nombre"
                  type="text"
                  className="text-field"
                  placeholder="Nombre del club"
                  value={info.Nombre}
                  onChange={(e) => setInfo({ ...info, Nombre: e.target.value })}
                />
                <label htmlFor="NombreCorto">Nombre Corto:</label>
                <input
                  name="NombreCorto"
                  type="text"
                  className="text-field"
                  placeholder="Nombre Corto"
                  value={info.NombreCorto}
                  onChange={(e) =>
                    setInfo({ ...info, NombreCorto: e.target.value })
                  }
                />
                <label htmlFor="Email">Email:</label>
                <input
                  name="Email"
                  type="email"
                  className="text-field"
                  placeholder="Email"
                  value={info.Email}
                  onChange={(e) => setInfo({ ...info, Email: e.target.value })}
                />
                <label htmlFor="Telefono">Teléfono:</label>
                <input
                  name="Telefono"
                  type="text"
                  className="text-field"
                  placeholder="Teléfono"
                  value={info.Telefono}
                  onChange={(e) =>
                    setInfo({ ...info, Telefono: e.target.value })
                  }
                />
                <label htmlFor="Pais">País: </label>
                <select
                  className="text-field"
                  value={info.Pais}
                  onChange={handleChangePais}
                  required
                >
                  {paises.map((option) => (
                    <option key={option.label} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <input
                  type="submit"
                  className="submit-button"
                  value="Actualizar"
                />
              </form>
              <a href="/enroll/agregar-miembros">
                <button className="boton">Siguiente</button>
              </a>
            </div>
          </>
        )}
        <div className="logout">
          <a href="/login">
            <button className="boton">Cerrar Sesión</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeClubs;
