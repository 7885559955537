import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "../../static/css/clubs/Verificacion.css";

const PasswordResetVerify = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [requestSent, setRequestSent] = useState(false); // Estado para controlar si la solicitud ya se ha enviado

  useEffect(() => {
    const controller = new AbortController();

    const code = queryParams.get("code");
    const sendInfo = async () => {
      try {
        if (!requestSent) {
          // Verificar si la solicitud ya se ha enviado
          const response = await axiosPrivate.post(
            "/auth/password-reset/verify/",
            JSON.stringify({ code }),
            {
              signal: controller.signal,
            }
          );
          alert(
            "Hemos enviado unas credenciales temporales al correo electrónico que brindaste."
          );
          navigate("/login");
          setRequestSent(true); // Establecer el estado de solicitud enviada a true después de enviar la solicitud
        }
      } catch (err) {
        // Manejar errores aquí
      } finally {
        // Realizar limpieza si es necesario
      }
    };

    sendInfo();

    return () => {
      controller.abort();
    };
  }, [location.search, axiosPrivate, navigate, requestSent]);

  return (
    <div className="verificacion">
      <div className="verification-animation">
        <div className="verifying-text">Verificando...</div>
      </div>
    </div>
  );
};

export default PasswordResetVerify;
