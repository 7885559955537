import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/ListaAtletas.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ExportExcel from "../../components/admin/ExcelExport";

const ListaClubs = () => {
  const axiosPrivate = useAxiosPrivate();
  const [clubs, setClubs] = useState([]);
  const options = [
    { value: "", text: "Año" },
    { value: "2023", text: "2023" },
    { value: "2024", text: "2024" },
  ];
  const [excelInfo, setexcelInfo] = useState([]);
  const [selected, setSelected] = useState(options[0].value);
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/club/enrolled/", {
          signal: controller.signal,
        });
        isMounted && setClubs(response.data.clubs);
      } catch (err) {}
      try {
        const response = await axiosPrivate.get("/api/club/enrolled/excel/", {
          signal: controller.signal,
        });
        isMounted && setexcelInfo(response.data.clubs);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);
  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
    let tempArray = [];

    if (value === "") {
      setSelection([]);
    } else {
      for (let i = 0; i < clubs.length; i++) {
        const club = clubs[i];
        if (club.Ano == value) {
          tempArray.push(club);
        }
      }
      setSelection(tempArray);
    }
  };
  return (
    <section className="ListaAtletas">
      <Sidebar />
      <div className="ListaAtletasContent">
        <h1>Listado de Clubes Inscritos</h1>
        <div className="Selector">
          Seleccione un Año:
          <select value={selected} onChange={handleChange}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <table>
          <thead>
            <th></th>
            <th>Nombre</th>
            <th>Nombre Corto</th>
            <th>Email</th>
            <th>Teléfono</th>
            <th>País</th>
            <th>Monto de Inscripcion</th>
            <th>Saldo Actual</th>
          </thead>
          <tbody>
            {selection.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <a href={`/admin/club/${item.Club_id}/`}>
                    {item.Club__Nombre}
                  </a>
                </td>
                <td>{item.Club__NombreCorto}</td>
                <td>{item.Club__Email}</td>
                <td>{item.Club__Telefono}</td>
                <td>{item.Club__Pais}</td>
                <td>{item.EnrollAmount}</td>
                <td>{item.CurrentBalance}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ExportExcel
          excelData={excelInfo}
          fileName={"Listado de Clubes Inscritos"}
        />
      </div>
    </section>
  );
};

export default ListaClubs;
