import { useEffect, useState } from "react";
import "../../static/css/clubs/AgregarMiembros.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import jwtDecode from "jwt-decode";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";

const AgregarMiembros = () => {
  const axiosPrivate = useAxiosPrivate();
  const [atletas, setAtletas] = useState([]);
  const [staff, setStaff] = useState([]);
  const id = jwtDecode(localStorage.getItem("refreshToken")).id;

  const DeleteStaff = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(`/api/staff/info/${id}/`, {
        signal: controller.signal,
      });
      if (response.status === 200) {
        alert("Staff eliminado satisfactoriamente.");
        window.location.reload(true);
      } else {
        alert("Ha ocurrido un error.");
      }
    } catch (err) {}
  };

  const DeleteAtleta = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(`/api/atleta/info/${id}/`, {
        signal: controller.signal,
      });
      if (response.status === 200) {
        alert("Atleta eliminado satisfactoriamente.");
        window.location.reload(true);
      } else {
        alert("Ha ocurrido un error.");
      }
    } catch (err) {}
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/clubdatafetch/", {
          signal: controller.signal,
          params: {
            id: id,
          },
        });
        isMounted && setAtletas(response.data.data.atletasInfo);
        isMounted && setStaff(response.data.data.staffInfo);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate, id]);
  return (
    <div className="AgregarMiembros">
      <header>
        <p>Agregar miembros al club</p>
      </header>
      <div className="main">
        <div className="tablas">
          <p className="subtitulo">Staff</p>
          <a href="/enroll/agregar-staff">
            <button className="boton">Agregar Staff</button>
          </a>
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Pasaporte</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Rol</th>
              </tr>
            </thead>
            <tbody>
              {staff.map((item) => (
                <tr key={item.id}>
                  <td>{item.Nombre + " " + item.Apellido}</td>
                  <td>{item.Pasaporte}</td>
                  <td>{item.Email}</td>
                  <td>{item.Telefono}</td>
                  <td>{item.Rol}</td>
                  <td>
                    <a onClick={() => DeleteStaff(item.id)}>
                      <PersonRemoveIcon />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="subtitulo">Gimnastas</p>
        <a href="/enroll/agregar-atletas">
          <button className="boton">Agregar Gimnastas</button>
        </a>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Fecha de Nacimiento</th>
              <th>Modalidad</th>
              <th>Programa</th>
              <th>Nivel</th>
            </tr>
          </thead>
          <tbody>
            {atletas.map((item) => (
              <tr key={item.id}>
                <td>{item.Nombre}</td>
                <td>{item.Apellido}</td>
                <td>{item.DOB}</td>
                <td>{item.Deporte}</td>
                <td>{item.Programa}</td>
                <td>{item.Nivel}</td>
                <td>
                  <a href={`/enroll/editar-atleta/${item.id}`}>
                    <EditIcon />
                  </a>
                </td>
                <td>
                  <a onClick={() => DeleteAtleta(item.id)}>
                    <PersonRemoveIcon />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="botones">
          <a href="/enroll">
            <button className="boton">Regresar</button>
          </a>
          <a href="/enroll/agregar-equipos">
            <button className="boton">Siguiente</button>
          </a>
        </div>
      </div>
      <div className="logout">
        <a href="/login">
          <button className="boton">Cerrar Sesión</button>
        </a>
      </div>
    </div>
  );
};
export default AgregarMiembros;
