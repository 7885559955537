import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "../../static/css/clubs/AgregarEquipos.css";
import axios from "../../utils/axios";
import jwtDecode from "jwt-decode";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const URL = "/api/equipo/info/";

const AgregarEquiposAdmin = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [Clubs, setClubs] = useState([]);
  const [Club, setClub] = useState("");
  const [selectedClub, setSelectedClub] = useState("");
  const [usagLevels, setUsagLevels] = useState([]);
  const [xcelLevels, setXcelLevels] = useState([]);
  const [upagLevels, setUpagLevels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/club/all/", {
          signal: controller.signal,
        });
        isMounted && setClubs(response.data.clubs);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  const handleCheckboxChange = (category, option) => {
    if (category === "USAG") {
      const updatedLevels = [...usagLevels];
      if (updatedLevels.includes(option)) {
        const index = updatedLevels.indexOf(option);
        updatedLevels.splice(index, 1);
      } else {
        updatedLevels.push(option);
      }
      setUsagLevels(updatedLevels);
    } else if (category === "XCEL") {
      const updatedLevels = [...xcelLevels];
      if (updatedLevels.includes(option)) {
        const index = updatedLevels.indexOf(option);
        updatedLevels.splice(index, 1);
      } else {
        updatedLevels.push(option);
      }
      setXcelLevels(updatedLevels);
    } else if (category === "UPAG") {
      const updatedLevels = [...upagLevels];
      if (updatedLevels.includes(option)) {
        const index = updatedLevels.indexOf(option);
        updatedLevels.splice(index, 1);
      } else {
        updatedLevels.push(option);
      }
      setUpagLevels(updatedLevels);
    }
  };

  const isLevelSelected = (category, option) => {
    if (category === "USAG") {
      return usagLevels.includes(option);
    } else if (category === "XCEL") {
      return xcelLevels.includes(option);
    } else if (category === "UPAG") {
      return upagLevels.includes(option);
    }
    return false;
  };

  const handleClubChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedClub = Clubs.find((clubes) => clubes.id === selectedId);
    setSelectedClub(selectedClub);
    setClub(selectedId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        URL,
        {
          club: Club,
          usagLevels: usagLevels,
          xcelLevels: xcelLevels,
          upagLevels: upagLevels,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${auth.accessToken}`,
          },
        }
      );
      if (response.data.created.length !== 0) {
        const createdString = response.data.created.join(", Nivel ");
        alert("Equipos creados: Nivel " + createdString);
      }
      if (response.data.duplicados.length !== 0) {
        const duplicadosString = response.data.duplicados.join(", Nivel ");
        alert("Equipos ya existentes: Nivel " + duplicadosString);
      }
      if (response.data.notCreated.length !== 0) {
        const notCreatedString = response.data.notCreated.join(", Nivel ");
        alert(
          "Equipos no creados por falta de miembros: Nivel " + notCreatedString
        );
      }
      navigate("/admin/");
    } catch (err) {}
  };

  return (
    <div className="AgregarEquipos">
      <header>
        <p>Agregar Equipo</p>
      </header>
      <form onSubmit={handleSubmit}>
        <p>
          Para agregar un equipo deben haber por lo menos 3 gimnastas en la
          categoría.
        </p>
        <select
          value={selectedClub ? selectedClub.id : ""}
          onChange={handleClubChange}
          required
        >
          <option value="">Selecciona un Club</option>
          {Clubs.map((clubes, index) => (
            <option key={`${clubes.id}-${index}`} value={clubes.id}>
              {clubes.Nombre}
            </option>
          ))}
        </select>
        <div className="fila">
          <div className="columna">
            <h1>USAG</h1>
            {[
              "Baby Gym",
              "Little Gym",
              "Future Star",
              "Beginner",
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
            ].map((level) => (
              <label key={level}>
                <input
                  type="checkbox"
                  checked={isLevelSelected("USAG", level)}
                  onChange={() => handleCheckboxChange("USAG", level)}
                />
                {typeof level === "number" ? `Nivel ${level}` : level}
              </label>
            ))}
          </div>
          <div className="columna">
            <h1>XCEL</h1>
            {["Bronze", "Silver", "Gold"].map((level) => (
              <label key={level}>
                <input
                  type="checkbox"
                  checked={isLevelSelected("XCEL", level)}
                  onChange={() => handleCheckboxChange("XCEL", level)}
                />
                {level}
              </label>
            ))}
          </div>
          <div className="columna">
            <h1>UPAG</h1>
            {["9-10", "11-12", "13-14"].map((level) => (
              <label key={level}>
                <input
                  type="checkbox"
                  checked={isLevelSelected("UPAG", level)}
                  onChange={() => handleCheckboxChange("UPAG", level)}
                />
                {level}
              </label>
            ))}
          </div>
        </div>
        <button type="submit">Agregar</button>
      </form>
    </div>
  );
};

export default AgregarEquiposAdmin;
