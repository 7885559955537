import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/HomeAdmin.css";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Mapa from "../../components/admin/Mapa";
import DashboardCards from "../../components/admin/DashboardCards";

const HomeAdmin = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [dashboardInfo, setDashboardInfo] = useState({});

  useEffect(() => {
    if (jwtDecode(auth.accessToken).firstLog === true) {
      navigate("/change-password");
    }
    let isMounted = true;
    const controller = new AbortController();
    const getCantidadAtletas = async () => {
      try {
        const response = await axiosPrivate.get(`/api/admin/dashboard/info/`, {
          signal: controller.signal,
        });
        isMounted && setDashboardInfo(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    getCantidadAtletas();
  }, [auth.accessToken, navigate]);

  return (
    <section className="HomeAdmin">
      <Sidebar />
      <div className="main">
        <h1>Dashboard</h1>
        <div className="row">
          <DashboardCards
            title="Total de atletas numerales"
            count={dashboardInfo.cantidadAtletasRegistrados}
            icon={"user"}
            color={"flat-color-1"}
          />
          <DashboardCards
            title="Total de clubes numerales"
            count={dashboardInfo.cantidadClubesRegistrados}
            icon={"users"}
            color={"flat-color-2"}
          />
          <DashboardCards
            title="Total de atletas nominales"
            count={dashboardInfo.cantidadAtletasInscritos}
            icon={"user"}
            color={"flat-color-3"}
          />
          <DashboardCards
            title="Total de clubes nominales"
            count={dashboardInfo.cantidadClubesInscritos}
            icon={"users"}
            color={"flat-color-4"}
          />
        </div>
        <div className="row">
          <DashboardCards
            title="Monto total"
            count={`$` + dashboardInfo.montoTotal}
            icon={"money"}
            color={"flat-color-1"}
          />
          <DashboardCards
            title="Monto pendiente"
            count={dashboardInfo.pagosPendientes}
            icon={"money"}
            color={"flat-color-4"}
          />
          <DashboardCards
            title="Monto recibido"
            count={dashboardInfo.pagosRecibidos}
            icon={"money"}
            color={"flat-color-3"}
          />
        </div>
      </div>
    </section>
  );
};

export default HomeAdmin;
