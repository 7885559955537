import React, { Component } from "react";
import "../../static/css/admin/CardStyling.css";

function DashboardCards(props) {
  return (
    <div className="card-column">
      <div className="card">
        <div className="card-body">
          <div className="stat-widget-five">
            <div className="stat-icon dib ">
              <i
                className={`fa fa-${props.icon} ${props.color}`}
                aria-hidden="true"
              ></i>
            </div>
            <div className="stat-content">
              <div className="text-left dib">
                <div className="stat-text">
                  <span className="count">{props.count}</span>
                </div>
                <div className="stat-heading">{props.title}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCards;
