import React from 'react';
import "../../static/css/clubs/Rechazada.css"

const Rechazada = () => {
  return (
    <div className="rechazada">
      <h2>Transacción Rechazada</h2>
      <p>¡La transacción no ha sido exitosa, por favor intente de nuevo.</p>
      <a href="/enroll/pagos"><button className="boton">Regresar</button></a>
    </div>
  );
};

export default Rechazada;
