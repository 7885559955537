import { React } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Home from "./pages/public/Home";
import Login from "./pages/public/Login";
import Register from "./pages/public/Register";
import HomeClubs from "./pages/clubs/HomeClubs";
import AgregarMiembros from "./pages/clubs/AgregarMiembros";
import AgregarStaff from "./pages/clubs/AgregarStaff";
import AgregarAtletas from "./pages/clubs/AgregarAtletas";
import AgregarEquipos from "./pages/clubs/AgregarEquipos";
import Pagos from "./pages/clubs/Pagos";
import HomeAdmin from "./pages/admin/HomeAdmin";
import AgregarAdministrador from "./pages/admin/AgregarAdministrador";
import ListaAtletas from "./pages/admin/ListaAtletas";
import ListaClubs from "./pages/admin/ListaClubs";
import ListaGeneralAtletas from "./pages/admin/ListaGeneralAtletas";
import ListaGeneralClubs from "./pages/admin/ListaGeneralClubs";
import ChangePassword from "./pages/admin/ChangePassword";
import AgregarPagos from "./pages/admin/AgregarPagos";
import HistorialPagos from "./pages/admin/HistorialPagos";
import Verificacion from "./pages/clubs/Verificacion";
import Aprobada from "./pages/clubs/Aprobada";
import Rechazada from "./pages/clubs/Rechazada";
import AgregarAtletasAdmin from "./pages/admin/AgregarAtletas";
import ListaStaff from "./pages/admin/ListaStaff";
import PasswordReset from "./pages/public/PasswordReset";
import PasswordResetVerify from "./pages/public/PasswordResetVerify";
import Club from "./pages/admin/Club";
import EditarAtletasAdmin from "./pages/admin/EditarAtletas";
import EditarAtletas from "./pages/clubs/EditarAtletas";
import ListaEquipos from "./pages/admin/ListaEquipos";
import CrearClub from "./pages/admin/CrearClub";
import AgregarStaffAdmin from "./pages/admin/AgregarStaff";
import AgregarEquiposAdmin from "./pages/admin/AgregarEquipos";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/code-verify" element={<PasswordResetVerify />} />
        <Route element={<PersistLogin />}>
          {/* Clubs routes */}
          <Route path="enroll" element={<RequireAuth allowedRoles={[1000]} />}>
            <Route path="" element={<HomeClubs />} />
            <Route path="agregar-miembros" element={<AgregarMiembros />} />
            <Route path="agregar-staff" element={<AgregarStaff />} />
            <Route path="agregar-atletas" element={<AgregarAtletas />} />
            <Route path="agregar-equipos" element={<AgregarEquipos />} />
            <Route path="editar-atleta/:id" element={<EditarAtletas />} />
            <Route path="pagos" element={<Pagos />} />
            <Route path="verificacion" element={<Verificacion />} />
            <Route path="aprobada" element={<Aprobada />} />
            <Route path="rechazada" element={<Rechazada />} />
          </Route>
          {/* Administradores */}
          <Route path="admin" element={<RequireAuth allowedRoles={[1100]} />}>
            <Route path="" element={<HomeAdmin />} />
            <Route path="agregar-admin" element={<AgregarAdministrador />} />
            <Route path="atletas-inscritos" element={<ListaAtletas />} />
            <Route path="atletas" element={<ListaGeneralAtletas />} />
            <Route path="clubs-inscritos" element={<ListaClubs />} />
            <Route path="agregar-club" element={<CrearClub />} />
            <Route path="clubs" element={<ListaGeneralClubs />} />
            <Route path="equipos" element={<ListaEquipos />} />
            <Route path="club/:id" element={<Club />} />
            <Route path="staff" element={<ListaStaff />} />
            <Route path="agregar-pagos" element={<AgregarPagos />} />
            <Route path="agregar-atletas" element={<AgregarAtletasAdmin />} />
            <Route path="agregar-staff" element={<AgregarStaffAdmin />} />
            <Route path="agregar-equipos" element={<AgregarEquiposAdmin />} />
            <Route path="editar-atleta/:id" element={<EditarAtletasAdmin />} />
            <Route path="historial-pagos" element={<HistorialPagos />} />
            {/* Inscripciones cerradas */}
            {/* <Route path="agregar-miembros" element={<AgregarMiembros />} />
            <Route path="agregar-staff" element={<AgregarStaff />} />
            <Route path="agregar-atletas" element={<AgregarAtletas />} />
            <Route path="agregar-equipos" element={<AgregarEquipos />} /> */}
            {/* Fin Inscripciones Cerradas */}
          </Route>
          {/* Cambio de contrasena */}
          <Route element={<RequireAuth allowedRoles={[1100, 1000]} />}>
            <Route path="change-password" element={<ChangePassword />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
