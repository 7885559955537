import "../../static/css/clubs/AgregarStaff.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";
import jwtDecode from "jwt-decode";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const URL = "/api/staff/info/";

const AgregarStaffAdmin = () => {
  const axiosPrivate = useAxiosPrivate();
  const [Nombre, setNombre] = useState("");
  const [Apellido, setApellido] = useState("");
  const [Pasaporte, setPasaporte] = useState("");
  const [Email, setEmail] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [Rol, setRol] = useState("");
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [Clubs, setClubs] = useState([]);
  const [Club, setClub] = useState("");
  const [selectedClub, setSelectedClub] = useState("");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/club/all/", {
          signal: controller.signal,
        });
        isMounted && setClubs(response.data.clubs);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  const RolOptions = [
    { value: "", text: "Rol" },
    { value: "Entrenador", text: "Entrenador" },
    { value: "Delegado", text: "Delegado" },
  ];

  const [RolSelected, setRolSelected] = useState(RolOptions[0].value);

  const handleChangeRol = (event) => {
    setRolSelected(event.target.value);
    setRol(event.target.value);
  };

  const handleClubChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedClub = Clubs.find((clubes) => clubes.id === selectedId);
    setSelectedClub(selectedClub);
    setClub(selectedId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        URL,
        JSON.stringify({
          Nombre,
          Apellido,
          Pasaporte,
          Email,
          Telefono,
          Rol,
          Club,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${auth.accessToken}`,
          },
        }
      );
      setNombre("");
      setApellido("");
      setPasaporte("");
      setTelefono("");
      setRol(RolOptions[0].value);
      alert("Staff agregado correctamente!");
      navigate("/admin/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="AgregarStaff">
      <header>
        <p>Agregar Staff</p>
      </header>
      <div className="main">
        <form onSubmit={handleSubmit} className="EditForm">
          <label>Club</label>
          <select
            value={selectedClub ? selectedClub.id : ""}
            onChange={handleClubChange}
            required
          >
            <option value="">Selecciona un Club</option>
            {Clubs.map((clubes, index) => (
              <option key={`${clubes.id}-${index}`} value={clubes.id}>
                {clubes.Nombre}
              </option>
            ))}
          </select>
          <input
            name="Nombre"
            type="text"
            class="text-field"
            placeholder="Nombre"
            value={Nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <input
            name="Apellido"
            type="text"
            class="text-field"
            placeholder="Primer Apellido"
            value={Apellido}
            onChange={(e) => setApellido(e.target.value)}
            required
          />
          <input
            name="Pasaporte"
            type="text"
            class="text-field"
            placeholder="Pasaporte"
            value={Pasaporte}
            onChange={(e) => setPasaporte(e.target.value)}
            required
          />
          <input
            name="Email"
            type="email"
            class="text-field"
            placeholder="Email"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            name="Telefono"
            type="text"
            class="text-field"
            placeholder="Teléfono"
            value={Telefono}
            onChange={(e) => setTelefono(e.target.value)}
            required
          />
          <select
            className="text-field"
            value={RolSelected}
            onChange={handleChangeRol}
            required
          >
            {RolOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          <input type="submit" className="submit-button" value="Agregar" />
        </form>
      </div>
    </div>
  );
};

export default AgregarStaffAdmin;
