import React, { useState, useEffect } from "react";
import boton from "../../static/img/club/boton.svg";
import "../../static/css/clubs/CreditCardForm.css";
import jwtDecode from "jwt-decode";

const CreditCardForm = (props) => {
  const monto = parseFloat(props.data / (1 - 0.05));
  const id = jwtDecode(localStorage.getItem("refreshToken")).id;

  const [enlace, setEnlace] = useState("");

  async function fetchPaymentLink(amount) {
    const data = {
      CCLW: "A572B74A841E3282301934897DBCE2C278DC4B42937245992B80BFABE0BB21ED31D2F059C5D54B8FA7230285BAB47DE0BF0C8B90ACF01825CDDC4874C313B25A", // prod
      // "CCLW": 'B7498F3E81DDDD62CF5E4B254B9BA191B0AFE1BE305C7AABEDF92E99BA75E8B8C94C141CE4CB9728DAEA702C6925C4D6179C0606EC06B611A924C57DC048B080', // demo
      CMTN: amount,
      PARM_1: id,
      CDSC: "Inscripción Copa IGA 2023",
      RETURN_URL: "https://copaigapanama.com/enroll/verificacion",
    };

    let postR = new URLSearchParams();
    for (const [mk, mv] of Object.entries(data)) {
      postR.append(mk, mv);
    }

    try {
      const response = await fetch(
        "https://secure.paguelofacil.com/LinkDeamon.cfm",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
          body: postR,
        }
      );

      const result = await response.json(); // Assuming the response is in JSON format
      return result.data.url;
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  useEffect(() => {
    async function fetchAndSetPaymentLink() {
      const link = await fetchPaymentLink(monto);
      setEnlace(link);
    }

    fetchAndSetPaymentLink();
  }, [monto]);

  return (
    <div className="CreditCardForm">
      {enlace && (
        <a href={enlace}>
          <img src={boton} alt="Pay Now" />
        </a>
      )}
    </div>
  );
};

export default CreditCardForm;
