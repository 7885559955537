import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/HistorialPagos.css"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const HistorialPagos = () => {
    const axiosPrivate = useAxiosPrivate();
    const [pagos, setPagos] = useState([])

    useEffect(() => {
        let isMounted = true;
		const controller = new AbortController();
        const getInfo = async () => {
			try{
				const response = await axiosPrivate.get('/api/pagos/', {
					signal: controller.signal,
				});
                console.log(response.data.Pagos)
				isMounted && setPagos(response.data.Pagos);
			} catch(err) {
			}
		}
		getInfo();
    }, [axiosPrivate]);

    return(
        <section className="HistorialPagos">
            <Sidebar />
            <div className="HistorialPagosContent">
                <h1>Historial de Pagos</h1>
                <table>
                    <thead>
                        <th></th>
                        <th>Club</th>
                        <th>Monto</th>
                        <th>Método de Pago</th>
                        <th>Fecha</th>
                    </thead>
                    {pagos && <tbody>
                    {pagos.map((item, index)=>(
                                <tr key={item.id}>
                                    <td>{index+1}</td>
                                    <td>{item.Club__Nombre}</td>
                                    <td>{item.Monto}</td>
                                    <td>{item.Metodo}</td>
                                    <td>{item.Fecha}</td>
                                </tr>
                            ))}
                    </tbody>}
                </table>
            </div>
        </section>
    )
}

export default HistorialPagos;