// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/club/FONDO PAGINA COPA.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AgregarStaff{\n    padding-top: 1%;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: center;\n    background-size: cover;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-family: 'Century Gothic Paneuropean', sans-serif;\n\n}\n\n.AgregarStaff header p{\n    font-size: 5vh;\n}", "",{"version":3,"sources":["webpack://./src/static/css/clubs/AgregarStaff.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mDAAyD;IACzD,2BAA2B;IAC3B,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,qDAAqD;;AAEzD;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".AgregarStaff{\n    padding-top: 1%;\n    background: url(\"../../img/club/FONDO\\ PAGINA\\ COPA.png\");\n    background-position: center;\n    background-size: cover;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-family: 'Century Gothic Paneuropean', sans-serif;\n\n}\n\n.AgregarStaff header p{\n    font-size: 5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
