import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth';
import '../../static/css/admin/ChangePassword.css'
import useLogout from "../../hooks/useLogout";
const URL = '/auth/change-password/'

const ChangePassword = () => {
    const { auth } = useAuth();
    const [password, setPassword] = useState("");
    const [password_verification, setPasswordVerification] = useState("");
	const navigate = useNavigate();
  	const logout = useLogout();

    const signOut = async () => {
      await logout();
      navigate('/login')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
             await axios.patch(URL,
              JSON.stringify({ password, password_verification }),
              {
                  headers: { 
                  'Content-Type': 'application/json', 
                  "Authorization": `JWT ${auth.accessToken}`,
                },
              }
          );
          setPassword('');
          setPasswordVerification('');
          alert("Contraseña actualizada satisfactoriamente!");
          signOut()
       }catch (err) {
      }
    }
    return(
        <section className='ChangePassword'>
            <div className='main'>
                <h2>Es primera vez que accedes a nuestro sistema, por eso debes cambiar la contraseña por defecto a una personalizada.</h2>
                <form onSubmit={handleSubmit} className="EditForm">
                  <label htmlFor="password">Contraseña:</label>
                  <input name='password' type='password' className='text-field' placeholder='Contrasena' value={password} onChange={(e) => setPassword(e.target.value)}/>
                  <label htmlFor="password">Ingrese la contraseña nuevamente: </label>
                  <input name='password' type='password' className='text-field' placeholder='Contrasena' value={password_verification} onChange={(e) => setPasswordVerification(e.target.value)}/>
                  {/* Selector de Nivel */}
                  <input type="submit" className="submit-button" value="Actualizar" />
                </form>
            </div>
        </section>
    )
}
export default ChangePassword