import { React, useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/AgregarPagos.css";
import axios from "../../utils/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
const URL = "/api/pagos/";

const AgregarPagos = () => {
  const [Clubs, setClubs] = useState([]);
  const [Club, setClub] = useState("");
  const [Monto, setMonto] = useState("");
  const [Metodo, setMetodo] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [selectedClub, setSelectedClub] = useState("");
  const { auth } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/club/enrolled/", {
          signal: controller.signal,
        });
        isMounted && setClubs(response.data.clubs);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  const handleClubChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedClub = Clubs.find((clubes) => clubes.id === selectedId);
    setSelectedClub(selectedClub);
    setClub(selectedClub.Club_id);
  };

  const MetodoOptions = [
    { value: "", text: "Método de Pago" },
    { value: "ACH", text: "ACH" },
    { value: "Western Union", text: "Western Union" },
  ];

  const [MetodoSelected, setMetodoSelected] = useState(MetodoOptions[0].value);

  const handleChangeMetodo = (event) => {
    setMetodoSelected(event.target.value);
    setMetodo(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(URL, JSON.stringify({ Club, Monto, Metodo }), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${auth.accessToken}`,
        },
      });
      setClub("");
      setMonto("");
      setMetodo("");
      alert("Pago agregado correctamente!");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="AgregarPagos">
      <Sidebar />
      {Clubs && (
        <div className="AgregarPagosContent">
          <h1>Agregar Pago</h1>
          <form onSubmit={handleSubmit} className="AgregarForm">
            {/* Selector de Club */}
            <select
              value={selectedClub ? selectedClub.id : ""}
              onChange={handleClubChange}
              required
            >
              <option value="">Selecciona un Club</option>
              {Clubs.map((clubes, index) => (
                <option key={`${clubes.id}-${index}`} value={clubes.id}>
                  {clubes.Club__Nombre}
                </option>
              ))}
            </select>

            <input
              name="Monto"
              type="text"
              className="text-field"
              placeholder="Monto"
              value={Monto}
              onChange={(e) => setMonto(e.target.value)}
              required
            />
            <select
              className="text-field"
              value={MetodoSelected}
              onChange={handleChangeMetodo}
              required
            >
              {MetodoOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            <input type="submit" className="submit-button" value="Agregar" />
          </form>
        </div>
      )}
    </div>
  );
};
export default AgregarPagos;
