import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../static/css/public/Login.css";
import axios from "../../utils/axios";
import logo from "../../static/img/public/4.png";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
const LOGIN_URL = "/auth/token/obtain/";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const userRef = useRef();
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    userRef.current.focus();
    localStorage.removeItem("refreshToken");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const accessToken = response?.data?.access;
      const roles = [jwtDecode(response?.data?.access).role];
      const firstLog = [jwtDecode(response?.data?.access).firstLog];
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("persist", true);

      setAuth({ username, password, roles, accessToken });
      setUsername("");
      setPassword("");
      if (firstLog[0] === true) {
        console.log(firstLog[0]);
        navigate("/change-password", { replace: true });
      } else if (roles[0] === 1100) {
        navigate("/admin", { replace: true });
      } else {
        navigate("/enroll", { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        alert("Error del servidor. Por favor intente más tarde.");
      } else if (err.response?.status === 400) {
        alert("Missing Username or Password");
      } else if (err.response?.status === 401) {
        alert("Usuario o contraseña incorrecto.");
      } else {
        alert("Error del inicio de sesión.");
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="login">
      <div className="login-page">
        <img className="lg" src={logo} alt="Logo" />
        <div className="form">
          <form onSubmit={handleSubmit} className="login-form">
            <div className="field">
              <input
                name="username"
                type="text"
                ref={userRef}
                className="text-field"
                placeholder="Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="field space">
              <input
                name="password"
                type="password"
                className="text-field"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="pass"></div>
            <div className="field">
              <input
                type="submit"
                className="submit-button"
                value="Iniciar Sesión"
              />
            </div>
          </form>
          <p className="message">
            No estás registrado? <a href="/register">Crea una cuenta</a>
          </p>
          <p className="message">
            <a href="/password-reset">Olvidé mi contraseña.</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Login;
