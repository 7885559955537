import "../../static/css/clubs/Pagos.css"
import { useEffect, useState } from "react";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import jwtDecode from "jwt-decode";
import CreditCardForm from "../../components/clubs/CreditCardForm";
import ACH from "../../components/clubs/ACH";
import WesternUnion from "../../components/clubs/WesternUnion";

const Pagos = () => {
    const axiosPrivate = useAxiosPrivate();
    const [info, setInfo] = useState({})
    const id = jwtDecode(localStorage.getItem("refreshToken")).id

    useEffect(() => {
        let isMounted = true;
		const controller = new AbortController();
        const getInfo = async () => {
			try{
				const response = await axiosPrivate.get(`/api/club/calcularmonto/${id}`, {
					signal: controller.signal,
				});
				isMounted && setInfo(response.data.data);
			} catch(err) {
			}
		}
		getInfo();
    }, [axiosPrivate, id]);
    const [selectedMethod, setSelectedMethod] = useState('');

    const handleChange = (event) => {
      setSelectedMethod(event.target.value);
    };


    return(
        <div className="Pagos">
            <header>
                <p>Pagos</p>
            </header>
            {info && <div className="main">
                <table>
                    <thead>
                        <th>Item</th>
                        <th>Monto</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Monto Atletas</td>
                            <td>{`$${info.montoAtletas}`}</td>
                        </tr>
                        <tr>
                            <td>Monto Equipos</td>
                            <td>{`$${info.montoEquipos}`}</td>
                        </tr>
                        <tr>
                            <td>Monto Total</td>
                            <td>{`$${info.montoTotal}`}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="metodos-de-pago">
                <label htmlFor="paymentMethod">Métodos de pago:</label>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  value={selectedMethod}
                  onChange={handleChange}
                >
                  <option value="">Selecciona un método de pago</option>
                  <option value="debit-credit-card">Tarjeta de debito o credito</option>
                  <option value="ach">ACH</option>
                  <option value="western">Western Union</option>
                </select>
                    {selectedMethod === 'debit-credit-card' && <CreditCardForm data={info.montoTotal} />}
                    {selectedMethod === 'ach' && <ACH data={info.montoTotal}/>}
                    {selectedMethod === 'western' && <WesternUnion data={info.montoTotal}/>}
                </div>
            </div>}
            <a href="/enroll/agregar-equipos"><button className="boton">Regresar</button></a>
        </div>
    );
}

export default Pagos;