import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/ListaAtletas.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

const ListaGeneralClubs = () => {
  const axiosPrivate = useAxiosPrivate();
  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/club/all/", {
          signal: controller.signal,
        });
        isMounted && setClubs(response.data.clubs);
      } catch (err) {}
    };
    getInfo();
    console.log(clubs);
  }, [axiosPrivate]);

  const DeleteClub = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(`/api/club/info/${id}`, {
        signal: controller.signal,
      });
      if (response.status === 200) {
        alert("Club eliminado satisfactoriamente.");
        window.location.reload(true);
      } else {
        alert("Ha ocurrido un error.");
      }
    } catch (err) {}
  };

  return (
    <section className="ListaAtletas">
      <Sidebar />
      <div className="ListaAtletasContent">
        <h1>Listado Numeral de Clubes</h1>
        <table>
          <thead>
            <th></th>
            <th>Nombre</th>
            <th>Nombre Corto</th>
            <th>Email</th>
            <th>Teléfono</th>
            <th>País</th>
            <th>Monto de Inscripcion</th>
            <th>Saldo Actual</th>
            <th>Año</th>
          </thead>
          <tbody>
            {clubs.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <a href={`/admin/club/${item.id}/`}>{item.Nombre}</a>
                </td>
                <td>{item.NombreCorto}</td>
                <td>{item.Email}</td>
                <td>{item.Telefono}</td>
                <td>{item.Pais}</td>
                <td>{item.EnrollAmount}</td>
                <td>{item.CurrentBalance}</td>
                <td>{item.Ano}</td>
                <td>
                  <a onClick={() => DeleteClub(item.id)}>
                    <PersonRemoveIcon />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ListaGeneralClubs;
