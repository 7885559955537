import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import AwesomeSlider from "react-awesome-slider";
import AwesomeSliderStyles from "react-awesome-slider/src/styles";
import "../../static/css/public/Home.css";
import logo from "../../static/img/public/4.png";
// import "../../static/js/public/Home"
import aboutus from "../../static/img/public/BS4A1200.JPG";
import copaiga from "../../static/img/public/_EDW0283.jpg";
import image1 from "../../static/img/public/1.jpg";
import image2 from "../../static/img/public/2.jpg";
import image3 from "../../static/img/public/3.jpg";
import image4 from "../../static/img/public/4.jpg";
import image5 from "../../static/img/public/5.jpg";
import image6 from "../../static/img/public/6.jpg";
import image7 from "../../static/img/public/7.jpg";
import image8 from "../../static/img/public/8.jpg";
import image9 from "../../static/img/public/9.jpg";
import image10 from "../../static/img/public/10.jpg";
import image11 from "../../static/img/public/11.jpg";
import image12 from "../../static/img/public/12.jpg";
import PDF1 from "../../static/pdf/COPA IGA GAF 2024.pdf";
import PDF2 from "../../static/pdf/COPA IGA TRM 2024.pdf";
import PDF3 from "../../static/pdf/HOTEL COPA IGA 2024.pdf";

const Home = () => {
  useEffect(() => {
    window.addEventListener("load", () => {
      let navbar = document.querySelector(".navbar");
      document.querySelector("#menu-btn").onclick = () => {
        navbar.classList.toggle("active");
      };

      window.onscroll = () => {
        navbar.classList.remove("active");
      };
    });
  });

  return (
    <div className="HomePage">
      <header className="HeaderHome">
        <a href="#" className="logo">
          <img src={logo} alt="logo" />
        </a>

        <nav className="navbar">
          <a href="#home">Inicio</a>
          <a href="#about">Sobre Nosotros</a>
          <a href="#copaiga">Copa IGA</a>
          <a href="#normativas">Normativas</a>
          <a href="#ediciones-anteriores">Ediciones Anteriores</a>
          {/* <a href="#home">Contacto</a> */}
        </nav>
        <div className="icons">
          <div id="menu-btn">
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </header>
      <section className="home" id="home">
        <div className="content">
          <h3 className="titulos">Inscripciones abiertas</h3>
          <p className="parrafos">
            Las inscripciones para la Copa IGA 2024 ya están abiertas. No
            pierdas la oportunidad de formar parte de este emocionante evento y
            demuestra tu talento en el escenario internacional.
          </p>
          <a href="/enroll" className="btn">
            Inscribirse
          </a>
        </div>
      </section>
      <section className="about" id="about">
        <h1 className="heading">
          <span>Sobre </span>Nosotros
        </h1>
        <div className="row">
          <div className="image">
            <img src={aboutus} alt="about us" />
          </div>
          <div className="content">
            <h3 className="subtitulos">International Gymnastics Academy</h3>
            <p className="parrafos">
              ¡Bienvenidos a la Copa IGA! Nos complace compartir con ustedes la
              historia detrás de este evento que ha cobrado vida gracias a la
              pasión y dedicación por la gimnasia artı́stica y de trampolı́n.
            </p>
            <p className="parrafos">
              Breve introducción: Hace 5 años (2019), nació la Copa IGA con el
              firme propósito de promover el desarrollo atlético y fomentar un
              espı́ritu competitivo sano en el mundo de la gimnasia. Lo que
              comenzó como un sueño compartido por un grupo de entusiastas
              entrenadores de nuestro club, se ha convertido en una competencia
              anual que reúne a talentosos atletas de diferentes paı́ses.
            </p>
          </div>
        </div>
      </section>
      <section className="copaiga" id="copaiga">
        <h1 className="heading">
          Copa <span>IGA</span>
        </h1>
        <div className="row">
          <div className="content">
            <h3 className="subtitulos">Evolución en 2 años.</h3>
            <p className="parrafos">
              Reseña histórica: En sus dos primeras ediciones, la Copa IGA ha
              sido testigo de momentos inolvidables en la gimnasia artı́stica y
              trampolı́n. Atletas de todas las edades y niveles han demostrado su
              habilidad y destreza en cada competencia, dejando una huella
              imborrable en nuestra memoria. Nos hemos convertido en un
              referente en la comunidad deportiva. En las ediciones anteriores
              2019 y 2022, recibimos a más de 1,000 atletas provenientes de 35
              clubes representando a Colombia, Costa Rica, México, El Salvador,
              Ecuador, Guatemala, República Dominicana, Perú, Trinidad y Tobago,
              Aruba, Bolivia y Panamá. Este evento brinda una plataforma para
              que atletas de diferentes paı́ses demuestren su talento y
              habilidades en gimnasia artı́stica y trampolı́n.
            </p>
            <p className="parrafos">
              La pasión por el deporte, el entusiasmo y el compromiso de
              nuestros atletas han sido el motor de este evento. Hemos sido
              testigos del crecimiento y la superación de cada participante, lo
              que ha fortalecido nuestro compromiso de seguir promoviendo la
              gimnasia en la región.
            </p>
            <h3 className="subtitulos">Nuestra meta:</h3>
            <p className="parrafos">
              Ser referente para Centroamérica y Sudamérica: En cada edición,
              nos esforzamos por mejorar y ser un referente en competencias de
              gimnasia artı́stica y trampolı́n en Centroamérica y Sudamérica.
              Buscamos inspirar a nuevas generaciones de atletas y fomentar una
              comunidad deportiva que comparte una misma pasión. Unidos por el
              deporte: Nuestra meta va más allá de la competencia. Queremos
              crear un espacio de unión y camaraderı́a, donde la diversidad de
              culturas y talentos se encuentren para celebrar la gimnasia en
              todo su esplendor.
            </p>
          </div>
          <div className="image">
            <img src={copaiga} alt="about us" />
          </div>
        </div>
      </section>
      <section className="normativas" id="normativas">
        <h1 className="heading">
          Normativas <span>2024</span>
        </h1>
        <div className="documentos">
          <embed
            src={PDF1}
            type="application/pdf"
            height={"100%"}
            width={"60%"}
          />
          <embed
            src={PDF2}
            type="application/pdf"
            height={"100%"}
            width={"60%"}
          />
          <embed
            src={PDF3}
            type="application/pdf"
            height={"100%"}
            width={"60%"}
          />
        </div>
      </section>
      <section className="ediciones-anteriores" id="ediciones-anteriores">
        <h1 className="heading">
          Ediciones <span>Anteriores</span>
        </h1>
        <AwesomeSlider cssModule={AwesomeSliderStyles}>
          <div data-src={image1} />
          <div data-src={image2} />
          <div data-src={image3} />
          <div data-src={image4} />
          <div data-src={image5} />
          <div data-src={image6} />
          <div data-src={image7} />
          <div data-src={image8} />
          <div data-src={image9} />
          <div data-src={image10} />
          <div data-src={image11} />
          <div data-src={image12} />
        </AwesomeSlider>
      </section>
    </div>
  );
};

export default Home;
