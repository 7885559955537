import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../static/css/public/Login.css";
import axios from "../../utils/axios";
import logo from "../../static/img/public/4.png";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
const URL = "auth/password-reset/obtain/";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar si se está enviando la solicitud

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Si ya se está procesando la solicitud, no hacer nada

    setIsSubmitting(true); // Establecer el estado de envío a true para indicar que se está procesando la solicitud

    try {
      const response = await axios.post(URL, JSON.stringify({ email }), {
        headers: { "Content-Type": "application/json" },
      });
      alert(
        "Si el correo electrónico está registrado en nuestros sistemas, enviaremos un enlace a esa dirección para el reinicio de la contraseña."
      );
      navigate("/login", { replace: true });
    } catch (err) {
      if (!err?.response) {
        alert("Error del servidor. Por favor intente más tarde.");
      } else if (err.response?.status === 400) {
        alert("Missing Username or Password");
      } else if (err.response?.status === 401) {
        alert("Usuario o contraseña incorrecto.");
      } else {
        alert("Error del inicio de sesión.");
      }
    } finally {
      setIsSubmitting(false); // Establecer el estado de envío a false después de que se complete la solicitud (ya sea exitosa o fallida)
    }
  };

  return (
    <div className="login">
      <div className="login-page">
        <img className="lg" src={logo} alt="Logo" />
        <div className="form">
          <p className="message">
            Por favor ingresa el correo electrónico que tienes registrado.
          </p>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="field">
              <input
                name="email"
                type="text"
                className="text-field"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <input
              type="submit"
              className="submit-button"
              value="Enviar"
              disabled={isSubmitting} // Deshabilitar el botón mientras se está procesando la solicitud
            />
          </form>
          <p className="message">
            No estás registrado? <a href="/register">Crea una cuenta</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default PasswordReset;
