import { useState, useEffect } from "react";
import "../../static/css/clubs/AgregarAtletas.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const URL = "/api/atleta/info/";

const EditarAtletas = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [info, setInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get(`/api/atleta/info/${id}`, {
          signal: controller.signal,
        });
        isMounted && setInfo(response.data.data);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosPrivate.patch(
        URL + id + "/",
        JSON.stringify({
          Nombre: info.Nombre,
          Apellido: info.Apellido,
          DOB: info.DOB,
        })
      );
      alert("Gimnasta editado correctamente!");
      navigate("/enroll/agregar-miembros/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="AgregarAtletas">
      <header>
        <p>Editar Gimnasta</p>
      </header>
      <div className="main">
        <form onSubmit={handleSubmit} className="EditForm">
          <label>Nombre</label>
          <input
            name="Nombre"
            type="text"
            className="text-field"
            placeholder="Nombre"
            value={info.Nombre}
            onChange={(e) => setInfo({ ...info, Nombre: e.target.value })}
            required
          />
          <label>Apellido</label>
          <input
            name="Apellido"
            type="text"
            className="text-field"
            placeholder="Primer Apellido"
            value={info.Apellido}
            onChange={(e) => setInfo({ ...info, Apellido: e.target.value })}
            required
          />
          <label>Fecha de Nacimiento</label>
          <input
            name="DOB"
            type="date"
            className="text-field"
            placeholder="DD/MM/YYYY"
            value={info.DOB}
            onChange={(e) => setInfo({ ...info, DOB: e.target.value })}
            required
          />
          <input type="submit" className="submit-button" value="Actualizar" />
        </form>
      </div>
    </div>
  );
};

export default EditarAtletas;
