import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/ListaAtletas.css";
import ExportExcel from "../../components/admin/ExcelExport";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ListaGeneralAtletas = () => {
  const axiosPrivate = useAxiosPrivate();
  const [atletas, setAtletas] = useState([]);
  const [excelInfo, setexcelInfo] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/atleta/all/", {
          signal: controller.signal,
        });
        isMounted && setAtletas(response.data.atletas);
      } catch (err) {}
      try {
        const response = await axiosPrivate.get("/api/atleta/excel/", {
          signal: controller.signal,
        });
        isMounted && setexcelInfo(response.data.atletas);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  return (
    <section className="ListaAtletas">
      <Sidebar />
      <div className="ListaAtletasContent">
        <h1>Listado Numeral de Atletas</h1>
        <table>
          <thead>
            <th></th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>DOB</th>
            <th>Deporte</th>
            <th>Programa</th>
            <th>Nivel</th>
            <th>Club</th>
            <th>Año</th>
          </thead>
          <tbody>
            {atletas.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.Nombre}</td>
                <td>{item.Apellido}</td>
                <td>{item.DOB}</td>
                <td>{item.Deporte}</td>
                <td>{item.Programa}</td>
                <td>{item.Nivel}</td>
                <td>{item.Club__Nombre}</td>
                <td>{item.Ano}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ExportExcel
          excelData={excelInfo}
          fileName={"Listado Numeral de Atletas"}
        />
      </div>
    </section>
  );
};

export default ListaGeneralAtletas;
