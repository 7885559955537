import { useState, useEffect } from "react";
import "../../static/css/clubs/AgregarAtletas.css";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
const URL = "/api/atleta/info/";

const AgregarAtletas = () => {
  const axiosPrivate = useAxiosPrivate();
  const [Nombre, setNombre] = useState("");
  const [Apellido, setApellido] = useState("");
  const [DOB, setDOB] = useState("");
  const [Programas, setProgramas] = useState("");
  const [Programa, setPrograma] = useState("");
  const [ProgramaTramp, setProgramaTramp] = useState([]);
  const [DeporteSelected, setDeporteSelected] = useState("");
  const [Deporte, setDeporte] = useState("");
  const [Nivel, setNivel] = useState("");
  const [Atletas, setAtletas] = useState({});
  const { auth } = useAuth();
  const [Club] = useState(jwtDecode(auth.accessToken).id);
  const navigate = useNavigate();
  const [NivelSelected, setNivelSelected] = useState("");
  const [ProgramSelected, setProgramSelected] = useState("");
  const [selectedAtleta, setSelectedAtleta] = useState(null);
  const [AgregarDespues, setAgregarDespues] = useState(false);
  const [Pareja, setPareja] = useState("");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get(`/api/club/atletas/${Club}/`, {
          signal: controller.signal,
        });
        isMounted && setAtletas(response.data.atletas);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  const handleAtletaChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedAtleta = Atletas.find((atleta) => atleta.id === selectedId);
    setSelectedAtleta(selectedAtleta);
  };

  const handleChangeNivel = (event) => {
    setNivelSelected(event.target.value);
    setNivel(event.target.value);
  };

  const handleChangeProgram = (event) => {
    setProgramSelected(event.target.value);
    setProgramas(event.target.value);
  };

  const handleChangeProgramTrampolin = (event) => {
    const programValue = event.target.value;

    if (ProgramaTramp.includes(programValue)) {
      setProgramaTramp(
        ProgramaTramp.filter((program) => program !== programValue)
      );
    } else {
      setProgramaTramp([...ProgramaTramp, programValue]);
    }
  };

  const handleDeporteChange = (event) => {
    setDeporteSelected(event.target.value);
    setDeporte(event.target.value);
    setProgramSelected("");
    setProgramas("");
    setNivelSelected("");
    setNivel("");
  };
  const handleAgregarDespuesChange = () => {
    setAgregarDespues(!AgregarDespues);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedAtleta) {
      setPareja(selectedAtleta.id);
    }

    if (Deporte === "Gimnasia Artistica") {
      setPrograma(Programas);
    } else if (
      Deporte === "Gimnasia en Trampolin" &&
      ProgramaTramp.length === 1
    ) {
      setPrograma(ProgramaTramp[0]);
    } else {
      setPrograma(ProgramaTramp.join(","));
    }
    if (Programa) {
      try {
        await axios.post(
          URL,
          JSON.stringify({
            Nombre,
            Apellido,
            DOB,
            Deporte,
            Programa,
            Nivel,
            Club,
            Pareja,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${auth.accessToken}`,
            },
          }
        );
        setNombre("");
        setApellido("");
        setProgramas("");
        setNivel("");

        alert("Gimnasta agregado correctamente!");
        navigate("/enroll/agregar-miembros");
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="AgregarAtletas">
      <header>
        <p>Agregar Gimnasta</p>
      </header>
      <div className="main">
        <form onSubmit={handleSubmit} className="EditForm">
          <label>Nombre</label>
          <input
            name="Nombre"
            type="text"
            className="text-field"
            placeholder="Nombre"
            value={Nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <label>Apellido</label>
          <input
            name="Apellido"
            type="text"
            className="text-field"
            placeholder="Primer Apellido"
            value={Apellido}
            onChange={(e) => setApellido(e.target.value)}
            required
          />
          <label>Fecha de Nacimiento</label>
          <input
            name="DOB"
            type="date"
            className="text-field"
            placeholder="DD/MM/YYYY"
            value={DOB}
            onChange={(e) => setDOB(e.target.value)}
            required
          />
          <label>Modalidad</label>
          <div className="ProgramSelector">
            <label>
              <input
                type="radio"
                value="Gimnasia Artistica"
                checked={DeporteSelected === "Gimnasia Artistica"}
                onChange={handleDeporteChange}
              />
              Gimnasia Artistica
            </label>
            <label>
              <input
                type="radio"
                value="Gimnasia en Trampolin"
                checked={DeporteSelected === "Gimnasia en Trampolin"}
                onChange={handleDeporteChange}
              />
              Gimnasia en Trampolin
            </label>
          </div>
          {DeporteSelected === "Gimnasia Artistica" && (
            <>
              <label>Programa</label>
              <div className="ProgramSelector">
                <label>
                  <input
                    type="radio"
                    value="USAG"
                    checked={ProgramSelected === "USAG"}
                    onChange={handleChangeProgram}
                  />{" "}
                  USAG
                </label>
                <label>
                  <input
                    type="radio"
                    value="XCEL"
                    checked={ProgramSelected === "XCEL"}
                    onChange={handleChangeProgram}
                  />{" "}
                  XCEL
                </label>
                <label>
                  <input
                    type="radio"
                    value="UPAG"
                    checked={ProgramSelected === "UPAG"}
                    onChange={handleChangeProgram}
                  />{" "}
                  UPAG
                </label>
              </div>
              <label>Nivel</label>
              {ProgramSelected === "USAG" && (
                <select
                  className="text-field"
                  value={Nivel}
                  onChange={handleChangeNivel}
                  required
                >
                  <option value={``}>Nivel</option>
                  <option value={`Baby Gym`}>Baby Gym</option>
                  <option value={`Little Gym`}>Little Gym</option>
                  <option value={`Future Star`}>Future Star</option>
                  <option value={`Beginner`}>Beginner</option>
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((level) => (
                    <option key={level} value={`Nivel ${level}`}>
                      Nivel {level}
                    </option>
                  ))}
                </select>
              )}
              {ProgramSelected === "XCEL" && (
                <select
                  className="text-field"
                  value={Nivel}
                  onChange={handleChangeNivel}
                  required
                >
                  <option value="">Nivel</option>
                  <option value="Bronze">Bronze</option>
                  <option value="Silver">Silver</option>
                  <option value="Gold">Gold</option>
                </select>
              )}
              {ProgramSelected === "UPAG" && (
                <select
                  className="text-field"
                  value={Nivel}
                  onChange={handleChangeNivel}
                  required
                >
                  <option value="">Nivel</option>
                  <option value="9-10">9 y 10 años</option>
                  <option value="11-12">11 y 12 años</option>
                  <option value="13-14">13 y 14 años</option>
                </select>
              )}
              <input type="submit" className="submit-button" value="Agregar" />
            </>
          )}
          {DeporteSelected === "Gimnasia en Trampolin" && (
            <>
              <label>Programa</label>
              <div className="ProgramSelector">
                <label>
                  <input
                    type="checkbox"
                    value="Trampolin"
                    checked={ProgramaTramp.includes("Trampolin")}
                    onChange={handleChangeProgramTrampolin}
                  />
                  Trampolin
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="MiniTramp"
                    checked={ProgramaTramp.includes("MiniTramp")}
                    onChange={handleChangeProgramTrampolin}
                  />
                  MiniTramp
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="DobleMiniTramp"
                    checked={ProgramaTramp.includes("DobleMiniTramp")}
                    onChange={handleChangeProgramTrampolin}
                  />
                  Doble MiniTramp
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Sincronismo"
                    checked={ProgramaTramp.includes("Sincronismo")}
                    onChange={handleChangeProgramTrampolin}
                  />
                  Sincronismo
                </label>
              </div>
              <label>Nivel</label>
              <select
                className="text-field"
                value={Nivel}
                onChange={handleChangeNivel}
                required
              >
                <option value="">Nivel</option>
                <option value="5-6 años">5-6 años</option>
                <option value="7-8 años">7-8 años</option>
                <option value="9-10 años">9-10 años</option>
                <option value="11-12 años">11-12 años</option>
                <option value="13-14 años">13-14 años</option>
                <option value="15-16 años">15-16 años</option>
                <option value="17-21 años">17-21 años</option>
                <option value="Senior">Senior</option>
              </select>
              {ProgramaTramp.includes("Sincronismo") && (
                <>
                  <label>Selecciona una pareja para el Sincronismo:</label>
                  <div className="agregar">
                    <label>Agregar despues: </label>
                    <input
                      type="checkbox"
                      checked={AgregarDespues}
                      onChange={handleAgregarDespuesChange}
                    />
                  </div>
                  {AgregarDespues === false ? (
                    <>
                      <select
                        value={selectedAtleta ? selectedAtleta.id : ""}
                        onChange={handleAtletaChange}
                        required
                      >
                        <option value="">Selecciona un atleta</option>
                        {Atletas.map((atleta) => (
                          <option key={atleta.id} value={atleta.id}>
                            {atleta.Nombre} {atleta.Apellido}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <input type="submit" className="submit-button" value="Agregar" />
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AgregarAtletas;
