import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/ListaAtletas.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ExportExcel from "../../components/admin/ExcelExport";

const ListaEquipos = () => {
  const axiosPrivate = useAxiosPrivate();
  const [equipos, setEquipos] = useState([]);
  const [excelInfo, setexcelInfo] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/club/equipo/", {
          signal: controller.signal,
        });
        isMounted && setEquipos(response.data.equipos);
        console.log(response.data.equipos);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);
  return (
    <section className="ListaAtletas">
      <Sidebar />
      <div className="ListaAtletasContent">
        <h1>Listado de Equipos Registrados</h1>
        <div className="Selector"></div>
        <table>
          <thead>
            <th></th>
            <th>Club</th>
            <th>Programa</th>
            <th>Nivel</th>
          </thead>
          <tbody>
            {equipos.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <a href={`/admin/club/${item.Club_id}/`}>
                    {item.Club__Nombre}
                  </a>
                </td>
                <td>{item.Programa}</td>
                <td>{item.Nivel}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <ExportExcel
          excelData={excelInfo}
          fileName={"Listado de Clubes Inscritos"}
        /> */}
      </div>
    </section>
  );
};

export default ListaEquipos;
