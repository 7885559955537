import React from 'react';
import "../../static/css/clubs/Aprobada.css"

const Aprobada = () => {
  return (
    <div className="aprobada">
      <h2>Transacción Aprobada</h2>
      <p>¡La transacción ha sido aprobada con éxito!</p>
      <a href="/login"><button className="boton">Regresar</button></a>
    </div>
  );
};

export default Aprobada;
