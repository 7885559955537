import jwtDecode from 'jwt-decode';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import "../../static/css/clubs/ACH.css"

const ACH = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const { data } = props;
    const navigate = useNavigate();
    const id = jwtDecode(localStorage.getItem("refreshToken")).id

    async function FinishEnroll() {
        const controller = new AbortController();
        try {
          const response = await axiosPrivate.get(`api/club/enroll/${id}/`, {
            signal: controller.signal,
          });
          if(response.status === 200) {
            alert("Inscripcion finalizada con exito!")
            navigate('/enroll');

          }
        } catch (error) {
          console.error("Error occurred:", error);
        }
      }
      

    return(
        <div className="Metodos">
            <p>Transferir ${data} a: </p>
            <p>Banco BAC International Bank, Inc</p>
            <p>No. de Cuenta Corriente: 104266093</p>
            <p>Beneficiario: Gymnastics PTY S.A.</p>
            <p>Correo: inscripcionescopaiga@gmail.com</p>
            <p>Swift: BCINPAPA</p>
            <p>Dirección: Aquilino de la Guardia Street, Urbanización Marbella, Panamá, República de Panamá</p>
            <br />
            <p>Banco Intermediario: JP Morgan Chase Bank NA</p>
            <p>Swift: CHASUS33</p>
            <p>Haba: 021000021</p>
            <p>Dirección: New York NYUSA</p>
            <br />
            <p className="confirmacion">Una vez presiones el botón de Aceptar, no podrás agregar atletas, ni staff, ni equipos. Tampoco podrás realizar cambios en la información del club.</p>
            <button className="boton" onClick={FinishEnroll}>Aceptar</button>
        </div>
    )
}
export default ACH;