import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Mapa = () => {
  const axiosPrivate = useAxiosPrivate();
  const [clubData, setClubData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get("/api/admin/dashboard/info");
        setClubData(response.data.clubesPorPais);
      } catch (error) {
        console.error("Error fetching club data:", error);
      }
    };

    fetchData();
  }, [axiosPrivate]);

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ComposableMap>
        <Geographies
          geography={
            "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"
          }
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              const countryName = geo.properties.NAME;
              const clubCount = clubData[countryName] || 0;

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    console.log(
                      `Country: ${countryName}, Club Count: ${clubCount}`
                    );
                  }}
                  style={{
                    default: {
                      fill: "#D6D6DA",
                      outline: "none",
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default Mapa;
