import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/ListaAtletas.css"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ExportExcel from "../../components/admin/ExcelExport";

const ListaStaff = () => {
    const axiosPrivate = useAxiosPrivate();
    const [excelInfo, setexcelInfo] = useState([])

    useEffect(() => {
        let isMounted = true;
		const controller = new AbortController();
        const getInfo = async () => {
			try{
				const response = await axiosPrivate.get('/api/staff/enrolled/excel/', {
					signal: controller.signal,
				});
				isMounted && setexcelInfo(response.data.staffs);
			} catch(err) {
			}
		}
		getInfo();
    }, [axiosPrivate]);

    return(
        <section className="ListaAtletas">
            <Sidebar />
            <div className="ListaAtletasContent">
                <h1>Listado de Staffs Inscritos</h1>
                <table>
                    <thead>
                        <th></th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Pasaporte</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Rol</th>
                        <th>Club</th>
                    </thead>
                    <tbody>
                    {excelInfo.map((item, index)=>(
                                <tr key={item.id}>
                                    <td>{index+1}</td>
                                    <td>{item.Nombre}</td>
                                    <td>{item.Apellido}</td>
                                    <td>{item.Pasaporte}</td>
                                    <td>{item.Email}</td>
                                    <td>{item.Telefono}</td>
                                    <td>{item.Rol}</td>
                                    <td>{item.Club}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <ExportExcel excelData={excelInfo} fileName={"Listado de Staffs Inscritos"} />
            </div>
        </section>
    )
}

export default ListaStaff;