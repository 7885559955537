import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "../../static/css/clubs/Verificacion.css";

const Verificacion = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    const Club = queryParams.get("PARM_1");
    const Monto = queryParams.get("TotalPagado");
    const Estado = queryParams.get("Estado");
    const Metodo = "Tarjeta";

    if (Estado === "Aprobada") {
      const SendInfo = async () => {
        try {
          const response = await axiosPrivate.post(
            "/api/pagos/",
            JSON.stringify({ Club, Monto, Metodo }),
            {
              signal: controller.signal,
            }
          );
          const respons = await axiosPrivate.get(`api/club/enroll/${Club}/`, {
            signal: controller.signal,
          });

          navigate("/enroll/aprobada");
        } catch (err) {
          console.error(err);
        } finally {
        }
      };

      SendInfo();
    } else {
      navigate("/enroll/rechazada");
    }

    return () => {
      controller.abort();
    };
  }, [location.search, axiosPrivate, navigate]);

  return (
    <div className="verificacion">
      <div className="verification-animation">
        <div className="verifying-text">Verificando...</div>
      </div>
    </div>
  );
};

export default Verificacion;
